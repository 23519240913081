import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import Container from '../Container';
import SingleGreenSquareIcon from '../../assets/icons/SingleSquareIcon';
import styles from './DevelopmentCardSection.module.scss';

const messages = defineMessages({
  mainTitle: {
    id: 'developmentCardSection.mainTitle',
    defaultMessage: 'Work with a team that goes the extra mile'
  },
  title: {
    id: 'developmentCardSection.title',
    defaultMessage: 'Our take on agile software development'
  },
  description: {
    id: 'developmentCardSection.description',
    defaultMessage:
      'We took “traditional” agile development and boosted it with meticulous attention to detail, project ownership, and proactive communication.'
  },
  descriptionRed: {
    id: 'developmentCardSection.descriptionRed',
    defaultMessage: 'Here’s what that means for you >'
  },
  firstBoxTitle: {
    id: 'developmentCardSection.firstBoxTitle',
    defaultMessage: 'The discovery call that gives back'
  },
  firstBoxDescription: {
    id: 'developmentCardSection.firstBoxDescription',
    defaultMessage:
      'Bring the 1,000 foot view of your project. In one mega-productive hour we’ll brainstorm how to turn your concept into a scalable product, where the opportunities are and how we can help.'
  },
  secondBoxTitle: {
    id: 'developmentCardSection.secondBoxTitle',
    defaultMessage: 'A spec that sweats the small stuff'
  },
  secondBoxDescription: {
    id: 'developmentCardSection.secondBoxDescription',
    defaultMessage:
      'Get a detailed work specification written by a project manager and a senior developer. Good planning in this stage lets us meet your requirements while staying flexible enough to accommodate new ideas.'
  },
  thirdBoxTitle: {
    id: 'developmentCardSection.thirdBoxTitle',
    defaultMessage:
      'Bi-weekly sprints, to-the-minute updates (aka peace of mind)'
  },
  thirdBoxDescription: {
    id: 'developmentCardSection.thirdBoxDescription',
    defaultMessage:
      'Continuous communication meets bi-weekly sprint updates and demos. You stay in the know and in control, without wasting hours on quality control and follow ups.'
  },
  fourthBoxTitle: {
    id: 'developmentCardSection.fourthBoxTitle',
    defaultMessage: 'Human-centered development'
  },
  fourthBoxDescription: {
    id: 'developmentCardSection.fourthBoxDescription',
    defaultMessage:
      'Rapid iterations and user testing makes sure everything is where it should be — for both your business and the user. From UX/UI to deployment, we test everything for speed, function and reliability.\n'
  },
  fifthBoxTitle: {
    id: 'developmentCardSection.fifthBoxTitle',
    defaultMessage: 'Regular review sessions'
  },
  fifthBoxDescription: {
    id: 'developmentCardSection.fifthBoxDescription',
    defaultMessage:
      'With so much experience in one room, both your team and ours will inevitably discover new use cases. Every delivery includes a discussion on opportunities for honing your product.'
  },
  sixthBoxTitle: {
    id: 'developmentCardSection.sixthBoxTitle',
    defaultMessage: 'Flexibility. Reliability. Check it off the listability.'
  },
  sixthBoxDescription: {
    id: 'developmentCardSection.sixthBoxDescription',
    defaultMessage:
      'Enjoy 100% tested software, the liberating feeling of a shrinking back-log and, most importantly, showers of compliments from happy users.'
  }
});

function DevelopmentCardSection() {
  return (
    <div className={styles.container}>
      <div className={styles.background} />
      <div className={styles.mainTitle}>
        <FormattedMessage {...messages.mainTitle} />
      </div>
      <Container className={styles.contentContainer}>
        <div className={styles.textContainer}>
          <div className={styles.title}>
            <FormattedMessage {...messages.title} />
          </div>
          <div className={styles.description}>
            <FormattedMessage {...messages.description} />
          </div>
          <div className={styles.redText}>
            <FormattedMessage {...messages.descriptionRed} />
          </div>
        </div>
        <div className={styles.cardContainer}>
          <div className={styles.content}>
            <div className={styles.icon}>
              <SingleGreenSquareIcon />
            </div>
            <div className={styles.sectionTitle}>
              <FormattedMessage {...messages.firstBoxTitle} />
            </div>
            <div className={styles.sectionDescription}>
              <FormattedMessage {...messages.firstBoxDescription} />
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.icon}>
              <SingleGreenSquareIcon />
            </div>
            <div className={styles.sectionTitle}>
              <FormattedMessage {...messages.secondBoxTitle} />
            </div>
            <div className={styles.sectionDescription}>
              <FormattedMessage {...messages.secondBoxDescription} />
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.icon}>
              <SingleGreenSquareIcon />
            </div>
            <div className={styles.sectionTitle}>
              <FormattedMessage {...messages.thirdBoxTitle} />
            </div>
            <div className={styles.sectionDescription}>
              <FormattedMessage {...messages.thirdBoxDescription} />
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.icon}>
              <SingleGreenSquareIcon />
            </div>
            <div className={styles.sectionTitle}>
              <FormattedMessage {...messages.fourthBoxTitle} />
            </div>
            <div className={styles.sectionDescription}>
              <FormattedMessage {...messages.fourthBoxDescription} />
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.icon}>
              <SingleGreenSquareIcon />
            </div>
            <div className={styles.sectionTitle}>
              <FormattedMessage {...messages.fifthBoxTitle} />
            </div>
            <div className={styles.sectionDescription}>
              <FormattedMessage {...messages.fifthBoxDescription} />
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.icon}>
              <SingleGreenSquareIcon />
            </div>
            <div className={styles.sectionTitle}>
              <FormattedMessage {...messages.sixthBoxTitle} />
            </div>
            <div className={styles.sectionDescription}>
              <FormattedMessage {...messages.sixthBoxDescription} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default DevelopmentCardSection;
