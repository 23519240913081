import React from 'react';
import { Helmet } from 'react-helmet';
import { defineMessages, FormattedMessage } from 'react-intl';
import App from '../App';
import Hero from '../components/Hero';
import ProjectSection from '../components/ProjectSection';
import ContactSection from '../components/ContactSection';
import DevelopmentCardSection from '../components/DevelopmentCardSection';
import GreenSquareIcon from '../assets/icons/GreenSquareIcon';
import SoftwareDevelopmentPageHeroImage from '../gatsbyImages/SoftwareDevelopmentPageHeroImage';
import CourtlyMockupImage from '../gatsbyImages/CourtlyMockupImage';
// import TotlMockupImage from '../gatsbyImages/TotlMockupImage';
import emilioBarajas from '../assets/images/emilio-barajas-avatar.png';
import josephTerpstra from '../assets/images/joseph-terpstra-avatar.png';
import NamedropMockupImage from '../gatsbyImages/NamedropMockupImage';
import * as paths from '../paths';
import styles from './pages.module.scss';

const messages = defineMessages({
  title: {
    id: 'softwareDevelopmentPage.title',
    defaultMessage: 'Own Your Market'
  },
  description1: {
    id: 'softwareDevelopmentPage.description1',
    defaultMessage: 'Build '
  },
  description2: {
    id: 'softwareDevelopmentPage.descriptio2',
    defaultMessage: 'user-centric products '
  },
  description3: {
    id: 'softwareDevelopmentPage.description3',
    defaultMessage:
      'around business logic. Do it with a team that {br} guarantees a '
  },
  description4: {
    id: 'softwareDevelopmentPage.description4',
    defaultMessage: 'smooth process '
  },
  description5: {
    id: 'softwareDevelopmentPage.description5',
    defaultMessage: 'and a '
  },
  description6: {
    id: 'softwareDevelopmentPage.description6',
    defaultMessage: 'valuable fresh perspective.'
  },
  courtlyPreTitle: {
    id: 'softwareDevelopmentPage.courtlyPreTitle',
    defaultMessage: 'Web Development'
  },
  courtlyTitle: {
    id: 'softwareDevelopmentPage.courtlyTitle',
    defaultMessage: 'Courtly x Streambright'
  },
  courtlyDescription: {
    id: 'softwareDevelopmentPage.courtlyDescription',
    defaultMessage:
      'What I loved was the attention to detail — building a roadmap where validation and development happened in tandem, managing the project from UX to final product flawlessly, and taking ownership over Courtly’s product as if it was their own.'
  },
  namedropPreTitle: {
    id: 'softwareDevelopmentPage.namedropPreTitle',
    defaultMessage: 'Mobile App Development'
  },
  namedropTitle: {
    id: 'softwareDevelopmentPage.namedropTitle',
    defaultMessage: 'Namedrop x Streambright'
  },
  namedropDescription: {
    id: 'softwareDevelopmentPage.namedropDescription',
    defaultMessage:
      'Adam Jermann and the entire team at StreamBright has exceeded every expectation I had when it came to their software development, quick response times and overall professionalism.'
  },
  totlPreTitle: {
    id: 'softwareDevelopmentPage.totlPreTitle',
    defaultMessage: 'Data Integration'
  },
  totlTitle: {
    id: 'softwareDevelopmentPage.totlTitle',
    defaultMessage: 'TOTL'
  },
  totlDescription: {
    id: 'softwareDevelopmentPage.totlDescription',
    defaultMessage:
      'The exceptional quality, level of brains, and project management that the StreamBright team brought to the table changed the game. If I could start over and give all my resources and attention to StreamBright and no one else, I would.'
  },
  button: {
    id: 'softwareDevelopmentPage.button',
    defaultMessage: 'Read case study'
  },
  contactSectionTitle: {
    id: 'softwareDevelopmentPage.contactSectionTitle',
    defaultMessage: 'How can we help you?'
  },
  contactSectionDescription: {
    id: 'softwareDevelopmentPage.contactSectionDescription',
    defaultMessage: `Tell us the 1,000 foot view of your project and we'll put our brains, tools and {br} processes in place to make it a reality.`
  }
});

function SoftwareDevelopmentPage() {
  return (
    <App>
      <Helmet>
        <title>Streambright - World Class Software Development</title>
        <meta
          name="description"
          content="Build user-centric products around business logic. Do it with a team that guarantees a smooth process and a valuable fresh perspective."
        />
      </Helmet>
      <Hero
        title={<FormattedMessage {...messages.title} />}
        description={
          <div className={styles.textContainer}>
            <FormattedMessage {...messages.description1} />
            <span className={styles.greenText}>
              <FormattedMessage {...messages.description2} />
            </span>
            <FormattedMessage
              {...messages.description3}
              values={{ br: <br /> }}
            />
            <span className={styles.greenText}>
              <FormattedMessage {...messages.description4} />
            </span>
            <FormattedMessage {...messages.description5} />
            <span className={styles.greenText}>
              <FormattedMessage {...messages.description6} />
            </span>
          </div>
        }
        icon={<GreenSquareIcon />}
        backgroundImage={SoftwareDevelopmentPageHeroImage}
      />
      <DevelopmentCardSection />
      <ProjectSection
        image={CourtlyMockupImage}
        imageClassName={styles.courtlyMockup}
        preTitle={messages.courtlyPreTitle}
        title={messages.courtlyTitle}
        description={messages.courtlyDescription}
        avatar={<img src={emilioBarajas} alt="Emilio Barajas" />}
        authorName="Emilio Barajas"
        jobTitle="CEO"
        variant="secondary"
        icon={
          <div className={styles.topIcon}>
            <GreenSquareIcon />
          </div>
        }
        quote
        to={paths.courtly()}
        buttonText={messages.button}
      />
      <ProjectSection
        image={NamedropMockupImage}
        imageClassName={styles.namedropMockup}
        preTitle={messages.namedropPreTitle}
        title={messages.namedropTitle}
        description={messages.namedropDescription}
        avatar={<img src={josephTerpstra} alt="Joseph Terpstra" />}
        authorName="Joseph Terpstra"
        jobTitle="Founder and CEO"
        reverse
        variant="secondary"
        quote
        to={paths.namedrop()}
        buttonText={messages.button}
      />
      {/* <ProjectSection
        image={TotlMockupImage}
        imageClassName={styles.courtlyMockup}
        preTitle={<FormattedMessage {...messages.totlPreTitle} />}
        title={<FormattedMessage {...messages.totlTitle} />}
        description={<FormattedMessage {...messages.totlDescription} />}
        variant="secondary"
        icon={<div className={styles.rightIcon}><GreenSquareIcon /></div>}
        quote
        to={paths.dataIntegration()}
        buttonText={messages.button}
      /> */}
      <div id="Contact">
        <ContactSection
          title={messages.contactSectionTitle}
          description={
            <FormattedMessage
              {...messages.contactSectionDescription}
              values={{ br: <br /> }}
            />
          }
        />
      </div>
    </App>
  );
}

export default SoftwareDevelopmentPage;
